import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // For navigation
import { db } from './firebase'; // Ensure the correct path
import { collection, addDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com'; // Import EmailJS

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    messageType: '',
    comment: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // State to track submission
  const navigate = useNavigate(); // For navigation

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation to check if all fields are filled out
    if (!formData.name || !formData.email || !formData.messageType || !formData.comment) {
      alert('Please fill out all fields before submitting.');
      return;
    }

    try {
      // Add form data to Firestore
      await addDoc(collection(db, 'contacts'), formData);
      console.log('Document written successfully');

      // Set up the EmailJS parameters
      // const emailParams = {
      //   name: formData.name,
      //   email: formData.email,
      //   messageType: formData.messageType,
      //   comment: formData.comment,
      // };

      // // Send email via EmailJS
      // emailjs.send(
      //   'service_z9t7961',  // Replace with your EmailJS service ID
      //   'template_krx2mvq', // Replace with your EmailJS template ID
      //   emailParams,
      //   'euqvDPBSn3Wy-Ma8A'      // Replace with your EmailJS user ID (public key)
      // )
      //   .then((response) => {
      //     console.log('Email sent successfully!', response.status, response.text);
      //   })
      //   .catch((error) => {
      //     console.error('Error sending email: ', error);
      //   });

      setIsSubmitted(true); // Set submission state to true
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  };



  // Reset form to allow another question
  const handleAskAnotherQuestion = () => {
    setFormData({
      name: '',
      email: '',
      messageType: '',
      comment: '',
    });
    setIsSubmitted(false); // Show form again
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 8,
        minHeight: '100vh',
        pt: 4,
        marginTop: '20px !Important'
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          width: '100%',
          padding: 2,
          '@media (min-width: 900px)': {
            maxWidth: 800,
          },
        }}
      >
        {isSubmitted ? (
          // Show success message and buttons after submission
          <div style={{ textAlign: 'center' }}>
            <div className="introTitle2" style={{ textAlign: 'center', marginBottom: '20px' }}>
              Thanks for your message!
            </div>
            <div className="introPar3" style={{ textAlign: 'center', marginBottom: '20px' }}>
              We'll get back to you soon.
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={handleAskAnotherQuestion}
              sx={{ mb: 2 }}
            >
              Have Another Question?
            </Button>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/')} // Navigate back to home
            >
              Return Home
            </Button>
          </div>
        ) : (
          // Show form before submission
          <>
            <div className="introTitle2" style={{ textAlign: 'center' }}>CONTACT US</div>
            <div className="introPar3" style={{ textAlign: 'center', marginBottom: '20px' }}>
              Have a question? Fill out this form and we'll get back to you in 2-3 days!
            </div>

            <form onSubmit={handleSubmit}>
              {/* Name Field */}
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{
                  style: { color: 'white' },
                  sx: {
                    '& fieldset': {
                      borderColor: 'white !important',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white !important',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white !important',
                    },
                  },
                }}
              />

              {/* Email Field */}
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{
                  style: { color: 'white' },
                  sx: {
                    '& fieldset': {
                      borderColor: 'white !important',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white !important',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white !important',
                    },
                  },
                }}
              />

              {/* Message Type Select */}
              <FormControl
                fullWidth
                margin="normal"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white !important',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white !important',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white !important',
                    },
                  },
                }}
              >
                <InputLabel sx={{ color: 'white' }}>Message Category</InputLabel>
                <Select
                  label="Message Category"
                  name="messageType"
                  value={formData.messageType}
                  onChange={handleChange}
                  sx={{
                    color: 'white',
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  }}
                >
                  <MenuItem value="general">General Inquiry</MenuItem>
                  <MenuItem value="feature">Feature Request</MenuItem>
                  <MenuItem value="feedback">Feedback</MenuItem>
                  <MenuItem value="shipping">Shipping Question</MenuItem>
                  <MenuItem value="return">Return Policy</MenuItem>
                  <MenuItem value="donation">Hurricane Relief Donations</MenuItem>
                </Select>
              </FormControl>

              {/* General Comment Field */}
              <TextField
                fullWidth
                label="Description"
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{
                  style: { color: 'white' },
                  sx: {
                    '& fieldset': {
                      borderColor: 'white !important',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white !important',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white !important',
                    },
                  },
                }}
              />

              {/* Submit Button */}
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                Submit
              </Button>
            </form>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Contact;
